import { API_URL } from '../constants';

function getUrlWithBase(url: string) {
  return `${API_URL}/api${url}`;
}

const defaultHeaders = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

const apiClient = {
  async get<ResponseType>(url: string, params?: RequestInit) {
    let response = await fetch(getUrlWithBase(url), {
      method: 'GET',
      ...defaultHeaders,
      ...params,
    });

    return await response.json() as Promise<ResponseType>;
  },
  async post<ResponseType>(url: string, params?: RequestInit) {
    let response = await fetch(getUrlWithBase(url), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      referrerPolicy: 'no-referrer-when-downgrade',
      ...params,
    });
    return await response.json() as Promise<ResponseType>;
  },
};

export default apiClient;
